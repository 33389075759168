<template>
  <BasePage :name="this.isSelfPresentation ? 'My Presentation' : 'Presentation of the Profile ID no.' + ' ' + String(this.form_data['unique_code'].val) ">
    <div v-if="!this.form_data['filled_at'].val">
      <div class="text_if_no_card">
      <LabelComponent  label_text="To create the Presentation, please visit"
                       label_type="button/large/regular 25"
                       style="line-height: 1.5"/>
      <br/>
      <ButtonComponent label="Questionnaire"
                       elementSize="big25"
                       elementType="default"
                       href="/questionnaire"/>
      </div>
    </div>
    <template v-else>
    <div v-if="this.isSelfPresentation">
      <div class="is_toogle_one_line_Q_1">
        <LabelComponent
                      label_text="Please revise your data carefully."
                      label_type="button/large/medium 18"
                     />
      </div>
      <div class="is_toogle_one_line_Q">
        <div>
        <LabelComponent
                        label_text="If any position to be modified, go back to"
                        label_type="button/large/medium 18"
                        />
          </div>
          <div>
        <ButtonComponent label="Questionnaire"
                        elementType="default"
                        elementSize="button/large/medium 18"
                        href="/questionnaire"/>
          </div>
      </div>
      <Separator separatorType = "horizontal" class = "presentation__separator"/>
    </div>
    <div class="date_of_change">
      <LabelComponent label_text="Date of completion or latest amendment" />
      <LabelComponent :label_text="this.formatedDate(this.form_data['filled_at'].val)" class="text_red"
                      />
    </div>
<div class='presentation-frame'>
  <LabelComponent   label_text="MY PERSONAL DATA"
                    label_type="second_big"/>

       <div class="presentation_info_block">
      <div class="flex-container">
      <LabelComponent label_text="Date of Birth"/>
      <LabelComponent :label_text="new Date(this.form_data['date_of_birth'].val).toLocaleDateString('ru', {time_zone:'UTC'})"
                      class="answer"/>
      </div>

      <div class="flex-container">
        <LabelComponent label_text="Height / Weight" />
        <LabelComponent :label_text="String(this.form_data['height'].val) + ' cm /'" class="answer" />
        <LabelComponent :label_text="String(this.form_data['weight'].val) + ' kg'" class="answer" />
      </div>

      <div class="flex-container">
        <LabelComponent label_text="Citizenship" />
        <LabelComponent :label_text="this.form_data['citizenship'].val.map((id)=> this.countries[id]).join(', ')"
                      class="answer" />
      </div>

      <div class="flex-container">
        <LabelComponent label_text="Color of eyes" />
        <LabelComponent :label_text="String(this.form_data['color_of_your_eyes'].val)"
                      class="answer" />
      </div>

      <div class="flex-container">
        <LabelComponent label_text="Place of residence" />
        <LabelComponent :label_text="String( this.countries[this.form_data['country_of_residence'].val])"
                      class="answer"/>
      </div>

      <div class="flex-container">
        <LabelComponent label_text="Color of hairs" />
        <LabelComponent :label_text="String(this.form_data['color_of_your_hairs'].val)"
                      class="answer" />
      </div>

      <div class="flex-container">
        <LabelComponent label_text="Ethnic group" />
        <LabelComponent :label_text="String( this.ethnic_group[this.form_data['ethnic_group'].val])"
                      class="answer"/>
      </div>

      <div class="flex-container">
        <LabelComponent  label_text="Body art, Piercing" />
        <LabelComponent :label_text="this.form_data['body_art_piercing'].val ? 'I have' : 'I do not have'"
                        class="answer" />
      </div>
   </div>

<br/>
  <LabelComponent   label_text="MY FIELD OF ACTIVITY"
                    label_type="second_big"/>

   <div class="presentation_info_block">
      <div class="flex-container">
        <LabelComponent label_text="Education" />
        <LabelComponent :label_text="String(this.education[this.form_data['education'].val])"
                      class="answer" />
      </div>

      <div class="flex-container">
        <LabelComponent label_text="Place of work" />
        <LabelComponent :label_text="String(this.form_data['place_of_work'].val)"
                      class="answer" />
      </div>

      <!-- <div class="flex-container">
        <LabelComponent label_text="Additional education" />
        <LabelComponent :label_text="String(this.form_data['additional_education'].val)"
                      class="answer" />
      </div> -->

      <div class="flex-container">
        <LabelComponent label_text="Position" />
        <LabelComponent :label_text="String(this.form_data['position'].val)"
                      class="answer" />
      </div>

      <div class="flex-container">
        <LabelComponent label_text="Profession" />
        <LabelComponent :label_text="String(this.profession[this.form_data['profession'].val])"
                      class="answer" />
      </div>

      <div class="flex-container">
        <LabelComponent label_text="Language skills" />
        <LabelComponent :label_text="this.form_data['foreign_languages'].val.map((id)=> this.languages[id]).join(', ')"
                      class="answer" />
      </div>

      <!-- <div class="flex-container">
      <LabelComponent label_text="I'm away or travel" />
      <LabelComponent :label_text="String(this.form_data['how_often_away_or_travel'].val)"
                    class="answer" />
      </div> -->
<!-- <div class="flex-container">
        <LabelComponent label_text="Other skills" />
        <LabelComponent :label_text="String(this.form_data['other_skills'].val)"
                      class="answer" />
      </div> -->

   </div>

<br/>
   <LabelComponent   label_text="MY FAMILY"
                    label_type="second_big"/>

  <div class="presentation_info_block">
      <div class="flex-container">
        <LabelComponent label_text="Family status" />
        <LabelComponent :label_text="String(this.family_status[this.form_data['family_status'].val])"
                      class="answer" />
      </div>

      <div class="flex-container">
      <LabelComponent label_text="Children" />
      <LabelComponent :label_text="this.form_data['child_set'].val && this.form_data['child_set'].val.length > 0 ? 'I have' : 'I don’t have'"
                    class="answer"/>
      <div v-for="child in this.form_data['child_set'].val" :key="child">
        <div class="one_line_text">
        <LabelComponent :label_text="String(this.sex[child['children_gender'].val])"
                        class="answer" />
        <LabelComponent :label_text="', ' + String(child['children_age'].val)  + ', ' "
                        class="answer" />
        <LabelComponent :label_text=" 'And we' + child['children_live_together'].val ? 'We live together' : 'We don’t live together'"
                        class="answer"/>
        </div>
      </div>
      </div>

      <!--
      <div>
        <LabelComponent label_text="Children live" />
        <LabelComponent  :label_text="this.form_data['children_live_together'].val ? 'with me' : 'separately'" />
      </div> -->

      <div class="flex-container">
        <LabelComponent label_text="More children" />
        <LabelComponent :label_text="this.form_data['more_children'].val? 'I want more children' : 'I am not planning on having children yet'"
                      class="answer" />
      </div>
  </div>

<LabelComponent   label_text="ABOUT ME"
                  label_type="second_big"/>
   <div class="presentation_info_block">
    <!-- <div class="flex-container">
      <LabelComponent label_text="Character traits" />
      <LabelComponent :label_text="String(this.form_data['character_traits'].val)"
                    class="answer" />
    </div> -->

    <div class="flex-container">
      <LabelComponent label_text="My ideas about an ideal future with my chosen one" />
      <LabelComponent :label_text="String(this.ideas_about_chosen[this.form_data['ideas_about_chosen'].val])"
                    class="answer" />
    </div>

    <div class="flex-container">
      <LabelComponent label_text="My personality type" />
      <LabelComponent :label_text="String(this.personality[this.form_data['personality'].val])"
                    class="answer" />
    </div>


    <div class="flex-container">
      <LabelComponent label_text="The place of residence of my potential chosen one" />
      <LabelComponent :label_text="this.form_data['does_place_matterr'].val.length > 0 ? this.form_data['does_place_matterr'].val.map((id)=> this.does_place_matterr[id]).join(', ') : 'Doesn’t matter'" class="answer" />
    </div>


    <!-- <div class="flex-container">
      <LabelComponent label_text="My life values" />
      <LabelComponent :label_text="String(this.form_data['life_values'].val)"
                      class="answer" />
    </div> -->

    <div class="flex-container">
      <LabelComponent label_text="Travelling" />
      <LabelComponent :label_text="this.form_data['like_to_travel'].val ? 'I like to travel' : 'I don’t like to travel'"
                      class="answer"/>
    </div>

    <!-- <div  v-for="countryId in this.form_data['visited_countries'].val" :key="countryId" class="flex-container">
      <LabelComponent label_text="I have already visited" />
      <LabelComponent :label_text="this.countries[countryId]"
                    class="answer" />
    </div> -->

    <div class="flex-container">
      <LabelComponent label_text="Driver license" />
      <LabelComponent :label_text="this.form_data['driving_licence'].val? 'I have' : 'I don’t have'"
                    class="answer" />
    </div>

    <!-- <div class="flex-container">
      <LabelComponent label_text="Own car" />
      <LabelComponent :label_text="this.form_data['own_car'].val? 'I have ' : 'I don’t have '"
                    class="answer" />
    </div> -->

    <!-- <div class="flex-container">
      <LabelComponent label_text="My vision of the future family relationships" />
      <LabelComponent :label_text="String(this.form_data['future_family'].val)"
                      class="answer" />
    </div> -->

    <div class="flex-container">
      <LabelComponent label_text="Cooking" />
      <LabelComponent :label_text="this.form_data['like_to_cook'].val? 'I like cooking' : 'I don’t like cooking'"
                    class="answer" />
    </div>

    <div class="flex-container">
      <LabelComponent label_text="My preferred vacation spot" />
      <LabelComponent :label_text="String(this.vacation_spot[this.form_data['vacation_spot'].val])"
                      class="answer"/>
    </div>

    <div class="flex-container">
      <LabelComponent label_text="My hobbies" />
      <LabelComponent :label_text="String(this.form_data['hobbies'].val)"
                    class="answer" />
    </div>

    <div class="flex-container">
      <LabelComponent label_text="My perfect vacation" />
      <LabelComponent :label_text="String(this.perfect_vacation[this.form_data['perfect_vacation'].val])"
                      class="answer"  />
    </div>

    <div class="flex-container">
      <LabelComponent label_text="Smoking" />
      <LabelComponent :label_text="this.form_data['smoke'].val? 'I smoke' : 'I don’t smoke'"
                    class="answer" />
    </div>

    <div class="flex-container">
      <LabelComponent label_text="Sport" />
      <LabelComponent :label_text="this.form_data['play_sport'].val? 'I regularly play sport' : 'I occasionally play sport'"
                    class="answer" />
    </div>
    <!-- <div>
      <LabelComponent label_text="Purpose of travels" />
      <LabelComponent :label_text="String(this.form_data['purpose_of_travels'].val)" />
    </div> -->

    <div class="flex-container">
      <LabelComponent label_text="My attitude towards people smoking" />
      <LabelComponent :label_text="String(this.other_people_smoking[this.form_data['other_people_smoking'].val])"
                    class="answer" />
    </div>

    <!-- <div class="flex-container">
      <LabelComponent label_text="Type of sport" />
      <LabelComponent :label_text="String(this.form_data['how_often_sport'].val)"
                    class="answer" />
    </div> -->

    <div class="flex-container">
      <LabelComponent label_text="Alcohol consumption" />
      <LabelComponent :label_text="String(this.alcohol[this.form_data['alcohol'].val])"
                    class="answer" />
    </div>

    <div class="flex-container">
      <LabelComponent label_text="My musical preferences" />
      <LabelComponent :label_text="String(this.form_data['music'].val)"
                      class="answer" />
    </div>

    <div class="flex-container">
      <LabelComponent label_text="Pets" />
      <LabelComponent :label_text="this.form_data['pets'].val ? 'I have' : 'I don’t have'"
                      class="answer" />
      <!-- <LabelComponent v-if="this.form_data['which_pet'].val != null"
                      :label_text="String(this.form_data['which_pet'].val)"
                      class="answer" /> -->
      <!-- <LabelComponent :label_text="this.form_data['which_pet'] ? 'I have pets' : 'I do not have pets'" class="answer" /> -->
        </div>

    <div class="flex-container">
      <LabelComponent label_text="My movie preferences" />
      <LabelComponent :label_text="String(this.form_data['movies'].val)"
                      class="answer"   />
    </div>
    </div>

<LabelComponent   label_text="MY CHOSEN ONE"
                    label_type="second_big"/>
  <div class="presentation_info_block">
    <!-- <div class="flex-container">
      <LabelComponent label_text="Character traits of my chosen one" />
      <LabelComponent :label_text="String(this.form_data['character_of_chosen'].val)"
                      class="answer" />
    </div> -->

    <div class="flex-container">
      <LabelComponent label_text="He/she may have children" />
      <LabelComponent :label_text="this.form_data['accept_have_children'].val? 'Yes' : 'No'"
                      class="answer"/>
    </div>
  </div>

<LabelComponent   label_text="MY PHOTOS & AUDIO/VIDEO"
                    label_type="second_big"/>
  <div class="presentation_info_block">
    <div class="photoes">
      <ZoomableIcon :url="form_data.media_photo1.val"
                      type="png"
                      size="30"
                      sizeUnit="%"
                      />
      <ZoomableIcon :url="form_data.media_photo2.val"
                      type="png"
                      size="30"
                      sizeUnit="%"
                      />
      <ZoomableIcon :url="form_data.media_photo3.val"
                      type="png"
                      size="30"
                      sizeUnit="%"
                      />
                      <video v-if="form_data.media_video.val != null" controls width="250">
        <source type="video/mp4" :src="form_data.media_video.val"/>
      </video>

      <audio  controls :src="form_data.media_audio.val" v-if="form_data.media_audio.val != null"></audio>
    </div>
        <!-- <div>
        <LabelComponent :label_text="String(this.form_data['name'].val)"/>
        <CheckBox       :label_text_left="this.form_data['plays_sport'].label"
                        check_box_field_status="disabled"
                        :init_value="this.form_data['plays_sport'].val"
                        />
      </div> -->
  </div>
</div>

    <div  v-if="this.isSelfPresentation" class="buttom_text_quest">
      <Separator separatorType = "horizontal" class = "user_info__separator"/>
      <div v-if="this.isSelfPresentation && !this.form_data.__presentation_payed__.val" >
      <!-- <LabelComponent   label_text="pres_buttom_text"
                        label_type ="button/large/medium 18"
                        style="line-height: 1.5"/> -->
      <!-- <LabelComponent   label_text="searching for suitable Candidates and for a pack of 5 Candidates."
                        label_type ="button/large/medium 18"
                        style="line-height: 1.5"/> -->
      <!-- <LabelComponent   label_text="Once you are satisfied, please publish your Presentation and go to the"
                              label_type ="button/large/medium 18"
                        style="line-height: 1.5"/>
      <ButtonComponent label="MY PROFILE Section."
                        elementType="default"
                        elementSize="big"
                        href="/userprofile"
                        style="line-height: 1.5"/> -->
      <LabelComponent   label_text="Once you are satisfied, please publish your Presentation."
                        label_type ="button/large/medium 18"
                        style="line-height: 1.5"/>
      <!-- <LabelComponent   label_text="Before entering your Presentation into our database, we would ask you to identify your personality."
                        label_type ="button/large/medium 18"
                        style="line-height: 1.5"/> -->
      </div>
       <div>
        <LabelComponent   label_text="Presentations of suitable Candidates will begin arriving into your CANDIDATES Section."
                          label_type ="button/large/medium 18"
                          style="line-height: 1.5"/>
      </div>
      <!-- <div>
        <ButtonComponent label="CANDIDATES Section"
                        elementType="default"
                        elementSize="big"
                        href="/packages"
                        style="line-height: 1.5"/>
      </div> -->

      <Separator separatorType = "horizontal" class = "user_info__separator"/>
    </div>

  <div class="create-pres-button">
    <ButtonComponent v-if="this.isSelfPresentation && !this.form_data.__presentation_payed__.val"
                    label="PUBLISH"
                    elementType="default-bg"
                    @click="this.payForPres()"/>
  </div>
  <br/>
  </template>
  </BasePage>
</template>

<script>
import BasePage from '@/baseLayout/BasePage';
import ButtonComponent from '@/units/forms/Button';
import LabelComponent from '@/units/Label';
import ZoomableIcon from '@/units/ZoomableIcon';
import Separator from '@/units/forms/Separator';

// import InputField from '@/units/forms/InputField';
// import DropDownInput from '@/units/forms/DropDownInput';
// import CheckBox from '@/units/forms/CheckBox';
// import InlineFormField from '@/units/forms/InlineFormField';

import getPayLink from '@/assets/dataSources/getPayLink';
import getRefList from '@/assets/dataSources/getRefList';
import getFormData from '@/assets/dataSources/getFormData';
import sendFormData from '@/assets/dataSources/sendFormData';

import getOtherPresentaionForm from '@/assets/dataSources/getOtherPresentaionForm';


export default ({
  components: {
    BasePage,
    LabelComponent,
    ZoomableIcon,
    ButtonComponent,
    Separator,
    // InputField,
    // DropDownInput,
    // CheckBox,
    // InlineFormField,
  },
  props: {
    unique_code: {
      type: String,
      required: false,
    },
  },
  watch: {
    $route(to, from) {
      if (String.comparePaths(to.path, from.path)) {
        return;
      }

      // при переключении роутера заново инициализируем параметры
      this.updatePresForm();
    },
  },
  data: ()=> ({
    form_data: {
      unique_code: {label: 'UserID', error: null, val: null, required: false},
      surname: {label: 'Last name', error: null, val: null, required: true},
      name: {label: 'First name', error: null, val: null, required: true},
      citizenship: {label: 'Citizenship', error: null, val: [], required: false, itype: 'dropdown'},
      country_of_residence: {label: 'Country of Residence', error: null, val: [], required: false, itype: 'dropdown'},
      sex: {label: 'Gender', error: null, val: [], required: false, itype: 'dropdown'},
      email: {label: 'Email', error: null, val: null, itype: 'email', required: false},
      date_of_birth: {label: 'Date of Birth', error: null, val: null, required: false},
      messenger: {label: 'Messenger', error: null, val: null, required: false},
      height: {label: 'Height', error: null, val: null, required: false},
      weight: {label: 'Weight', error: null, val: null, required: false},
      color_of_your_eyes: {label: 'Color of your eyes', error: null, val: null, required: false},
      color_of_your_hairs: {label: 'Color of your hairs', error: null, val: null, required: false},
      body_art_piercing: {label: 'Body art, Piercing', error: null, val: null, required: false},
      ethnic_group: {label: 'Ethnic Group', error: null, val: [], required: false, itype: 'dropdown'},
      education: {label: 'Education', error: null, val: [], required: false, itype: 'dropdown'},
      profession: {label: 'Profession', error: null, val: [], required: false, itype: 'dropdown'},
      additional_education: {label: 'Additional education', error: null, val: null, required: false},
      other_skills: {label: 'Other skills', error: null, val: null, required: false},
      place_of_work: {label: 'Place of work', error: null, val: null, required: false},
      position: {label: 'Position', error: null, val: null, required: false},
      foreign_languages: {label: 'Language skills', error: null, val: [], required: false, itype: 'dropdown'},
      family_status: {label: 'Family status', error: null, val: [], required: false, itype: 'dropdown'},
      like_to_cook: {label: 'Do you like to cook?', error: null, val: null, required: false},
      character_traits: {label: 'Your character traits', error: null, val: null, required: false},
      personality: {label: 'Your personality type', error: null, val: [], required: false, itype: 'dropdown'},
      hobbies: {label: 'Your hobbies', error: null, val: null, required: false},
      how_often_away_or_travel: {label: 'How often are you away', error: null, val: null, required: false},
      play_sport: {label: 'Do you play sports?', error: null, val: null, required: false},
      how_often_sport: {label: 'Which one and how often', error: null, val: null, required: false},
      smoke: {label: 'Do you smoke?', error: null, val: null, required: false},
      other_people_smoking: {label: 'How do you feel about other people smoking?', error: null, val: [], required: false, itype: 'dropdown'},
      alcohol: {label: 'What is your attitude towards alcohol?', error: null, val: [], required: false, itype: 'dropdown'},
      pets: {label: 'Do you have pets?', error: null, val: null, required: false},
      which_pet: {label: 'Which ones', error: null, val: null, required: false},
      driving_licence: {label: 'Do you have a driving license?', error: null, val: null, required: false},
      own_car: {label: 'Do you own a car?', error: null, val: null, required: false},
      like_to_travel: {label: 'Do you like to travel?', error: null, val: false, required: false},
      visited_countries: {label: 'Which countries have you already visited?', error: null, val: [], required: false, itype: 'dropdown'},
      life_values: {label: 'Your life values', error: null, val: false, required: false},
      vacation_spot: {label: 'Preferred vacation spot ', error: null, val: [], required: false, itype: 'dropdown'},
      perfect_vacation: {label: 'My perfect vacation', error: null, val: [], required: false, itype: 'dropdown'},
      music: {label: 'Your musical preferences', error: null, val: null, required: false},
      movies: {label: 'Your movie preferences', error: null, val: null, required: false},
      character_of_chosen: {label: 'Character traits of your chosen one', error: null, val: null, required: false},
      ideas_about_chosen: {label: 'What are your ideas about an ideal future with your chosen one?', error: null, val: [], required: false, itype: 'dropdown'},
      future_family: {label: 'How do you see your future family relationships?', error: null, val: null, required: false},
      does_place_matterr: {label: 'Does the place of residence of your potential chosen one matter to you?', error: null, val: [], required: false, itype: 'dropdown'},
      accuracy: {label: 'I confirm the accuracy of the data', error: null, val: null, required: false},
      full_registration: {label: 'For full registration at the Agency, you will need to accept a', error: null, val: null, required: false},
      // privacy_policy_agreed: {label: 'I agree with the privacy policy', error: null, val: false},

      // child: {label: 'Do you have children?', error: null, val: false, required: false},
      child_set: {
        label: 'Children',
        error: null,
        val: null,
        itype: 'inline',
        content_form: {
          children_age: {label: 'Child age', error: null, val: null, itype: 'number'},
          children_gender: {label: 'Child gender', error: null, val: null, itype: 'dropdown', ddVals: []},
          children_live_together: {label: 'Do your children live with you?', error: null, val: false, itype: 'boolean'},
        },
      },
      accept_have_children: {label: 'Do you accept that he/she may have children?', error: null, val: null, required: false},

      more_children: {label: 'Would you like to have more children?', error: null, val: false, required: false},

      preferred_education: {label: 'Education', error: null, val: [], required: false},
      preferred_profession: {label: 'Profession', error: null, val: [], required: false},
      age_from: {label: 'Age from', error: null, val: null, required: false},
      age_to: {label: 'Age to', error: null, val: null, required: false},
      height_from: {label: 'Height from', error: null, val: null, required: false},
      height_to: {label: 'Height to', error: null, val: null, required: false},
      weight_from: {label: 'Weight from', error: null, val: null, required: false},
      weight_to: {label: 'Weight to', error: null, val: null, required: false},
      preferred_citizenship: {label: 'Citizenship', error: null, val: [], required: false},
      preferred_country_of_residence: {label: 'Country of Residence', error: null, val: [], required: false},
      preferred_ethnic_group: {label: 'Preffered ethnic Group', error: null, val: [], required: false},
      preferred_foreign_languages: {label: 'Language skills', error: null, val: [], required: false},

      media_photo1: {label: 'Select first photo', error: null, val: null, required: false},
      media_photo2: {label: 'Select second photo', error: null, val: null, required: false},
      media_photo3: {label: 'Select third photo', error: null, val: null, required: false},
      media_video: {label: 'Select an audio (optional)', error: null, val: null, required: false},
      media_audio: {label: 'Select a video (optional)', error: null, val: null, required: false},
      __presentation_payed__: {label: 'Select a video (optional)', error: null, val: false, required: false},
      filled_at: {label: 'Date of completion or latest amendment', error: null, val: null, required: false},
    },
    countries: {},
    profession: {},
    education: {},
    languages: {},
    family_status: {},
    sex: {},
    personality: {},
    vacation_spot: {},
    perfect_vacation: {},
    ideas_about_chosen: {},
    other_people_smoking: {},
    alcohol: {},
    does_place_matterr: {},
    ethnic_group: {},
  }),
  mounted() {
    this.updateRefs();
  },
  computed: {
    isSelfPresentation() {
      return this.$route.params.presentation_owner == 'self';
    },
  },
  methods: {
    formatedDate(date) {
      if (!date) {
        return '-';
      }
      date=new Date(date);
      const dateText = date.toLocaleDateString('ru-RU', {timeZone: 'Europe/Zurich'});
      const timeText = date.toLocaleTimeString('en-EN', {timeZone: 'Europe/Zurich', hour: '2-digit', minute: '2-digit'});

      // datetime
      return dateText + ' ' + timeText;
    },
    payForPres() {
      this.$router.replace({'query': null});

      if (!this.isSelfPresentation || this.form_data.__presentation_payed__.val) {
        this.updatePresForm();
        return;
      }

      // this.updatePresForm();

      sendFormData(async (result) => {
        if (result.status == 200) {
          // window.globalEvents.callEvent('new_message', [{
          //   messageType: 'success',
          //   messageData: 'Оплата и публикация презентации прошла успешно!',
          // }]);
          this.updatePresForm();
          this.$router.replace({path: '/userprofile'});
          return;
        }

        const errorText = await result.text();

        if (result.status == 400 && String(errorText).startsWith('pay_req')) {
          // window.globalEvents.callEvent('new_message', [{
          //   messageType: 'error',
          //   messageData: {
          //     name: 'Error',
          //     message: 'Оплата и публикация презентации не произошла пополните кошелек!',
          //   },
          // }]);

          getPayLink((link) => {
            const returnUrl = encodeURIComponent('/userprofile'); // Замените на реальный URL страницы userprofile
            // window.open(link, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
            // this.$store.commit('set_full_screen_loading', true);
            window.open(link, '_blank');
          }, Number.parseFloat(String(errorText).split('|')[1]), '/presentation/self/?autopay=1');
          return;
        }

        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: {
            name: 'Error',
            message: 'Bad server response! ' + result.status,
          },
        }]);
      }, 'pay_presentation');
    },
    setPresData(servData) {
      Object.keys(servData).forEach((extKey) => {
        if (this.form_data[extKey] == null) return;

        if (this.form_data[extKey].itype == 'inline') {
          this.form_data[extKey].val = [];

          servData[extKey].forEach((data) => {
            const newElem = {};

            Object.keys(this.form_data[extKey].content_form).forEach((field) => {
              const newField = {};
              const curField = this.form_data[extKey].content_form[field];
              Object.keys(curField).forEach((attr) => newField[attr] = curField[attr]);
              newElem[field] = newField;
              if (data[field] != null && newElem[field].itype == 'dropdown' && !Array.isArray(data[field])) {
                data[field] = [data[field]];
              }
              newElem[field].val = data[field];
            });

            this.form_data[extKey].val.push(newElem);
          });

          return;
        }

        if (servData[extKey] != null && this.form_data[extKey].itype == 'dropdown' && !Array.isArray(servData[extKey])) {
          servData[extKey] = [servData[extKey]];
        }

        this.form_data[extKey].val = servData[extKey];
      });
    },
    updatePresForm() {
      if (this.isSelfPresentation) {
        getFormData((servData) => {
          this.setPresData(servData);
          if (this.$route.query.autopay == '1') {
            console.log('AUTOPAYED!');
            this.payForPres();
          }
        }, 'presentation');
      } else {
        getOtherPresentaionForm((servData) => {
          this.setPresData(servData);
        }, this.$route.params.presentation_owner);
      }
    },
    updateRefs() {
      this.updatePresForm();
      getRefList((newList) => {
        // newList.shift();
        // this.countries = newList;
        newList.forEach((elem)=>this.countries[elem.id]=elem.view);
      }, 'Country');
      getRefList((newList) => {
        // newList.shift();
        newList.forEach((elem)=>this.profession[elem.id]=elem.view);
      }, 'Profession');
      getRefList((newList) => {
        // newList.shift();
        newList.forEach((elem)=>this.education[elem.id]=elem.view);
      }, 'Education');
      getRefList((newList) => {
        // newList.shift();
        newList.forEach((elem)=>this.languages[elem.id]=elem.view);
      }, 'Languages');
      getRefList((newList) => {
        // newList.shift();
        newList.forEach((elem)=>this.family_status[elem.id]=elem.view);
      }, 'FamilyStatus');
      getRefList((newList) => {
        // newList.shift();
        newList.forEach((elem)=>this.sex[elem.id]=elem.view);
      }, 'Sex');
      getRefList((newList) => {
        // newList.shift();
        newList.forEach((elem)=>this.personality[elem.id]=elem.view);
      }, 'Personality');
      getRefList((newList) => {
        // newList.shift();
        newList.forEach((elem)=>this.vacation_spot[elem.id]=elem.view);
      }, 'VacationSpot');
      getRefList((newList) => {
        // newList.shift();
        newList.forEach((elem)=>this.perfect_vacation[elem.id]=elem.view);
      }, 'PerfectVacation');
      getRefList((newList) => {
        // newList.shift();
        newList.forEach((elem)=>this.ideas_about_chosen[elem.id]=elem.view);
      }, 'IdeasAboutChosen');
      getRefList((newList) => {
        // newList.shift();
        newList.forEach((elem)=>this.other_people_smoking[elem.id]=elem.view);
      }, 'OtherPeopleSmoking');
      getRefList((newList) => {
        // newList.shift();
        newList.forEach((elem)=>this.alcohol[elem.id]=elem.view);
      }, 'Alcohol');
      // getRefList((newList) => {
      //   // newList.shift();
      //   this.child = newList;
      // }, 'Child');
      getRefList((newList) => {
        // newList.shift();
        newList.forEach((elem)=>this.does_place_matterr[elem.id]=elem.view);
      }, 'DoesPlaceMatter');
      getRefList((newList) => {
        // newList.shift();
        // newList.shift();
        newList.forEach((elem)=>this.ethnic_group[elem.id]=elem.view);
      }, 'EthnicGroup');
    },
  },
});
</script>

<style lang="less">
.one_line_text{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.buttom_text_quest{
  text-align: center;
}
.one_line_ques{
  display: flex;
}
.go_back_to_Q{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    padding: 13px;
}
.presentation__separator {
    margin: 3px 3px;
  }
.presentation_info_block{
 .flex(row, start, start);
 flex-wrap: wrap;
 gap: 10px
}
.form_field {
  min-width: 250px;
  width: 50%;
  display: flex;
  padding: 13px;
  flex-direction: column;
}
.date_of_change{
  display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
    align-content: flex-end;
    align-items: flex-end;
}
.is_toogle_one_line_Q_1{
  display: flex;
    text-align: center;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 23px;
}
.is_toogle_one_line_Q{
    display: flex;
    text-align: center;
    padding: 2px;
    gap: 8px;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 13px;
    margin-bottom: 13px;
}
.photoes{
  display: flex;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Slight shadow */
  border-radius: 8px; /* Rounded corners */
  padding: 10px; /* Adjust padding as needed */
  margin-bottom: 10px; /* Add some margin between containers if desired */
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 30px;  /* Расстояние между "Описание" и "Ответ" */
}
@media screen and (max-width: @transition-threshold-1) {
  margin-top: 15px;
  display: flex;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Slight shadow */
  border-radius: 8px; /* Rounded corners */
  padding: 10px; /* Adjust padding as needed */
  margin-bottom: 10px; /* Add some margin between containers if desired */
  display: flex;
  align-items: center;
  width: 50%;
  gap: 30px;  /* Расстояние между "Описание" и "Ответ" */
}
.presentation-frame {
  padding: 20px;

  .flex-container {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    gap: 8px;
    width: 49%;
    min-width: 250px;
    max-width: 800px;
  }
  .answer {
  font-size: 16px; /* Размер шрифта для ответов */
  color: @other-color-1;
}
  .form-group {
    margin-bottom: 30px;
  }

  .policy-field {
    .flex(row, center, center);
  }
}
</style>
